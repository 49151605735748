import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  carouselControllersWrapper: {},
  carouselControllerLabel: {
    color: theme.companyTheme?.textColor?.toString(),
    padding: 5,
  },
  carouselControllerButton: {
    color: theme.companyTheme?.textColor?.toString(),
  },
}));

export { useStyles };
