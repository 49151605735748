import React from "react";
import ReactDOM from "react-dom/client";
import BootstrapComponent from "./bootstrap";
import "./index.css";
import "./variables.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const RenderNode = () => {
  const isCypress = window.hasOwnProperty("Cypress");
  if (isCypress) {
    return <BootstrapComponent />;
  }
  return (
    <React.StrictMode>
      <BootstrapComponent />
    </React.StrictMode>
  );
};
root.render(<RenderNode />);
