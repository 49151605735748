import Color from "color";
import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  "@keyframes moveMenuUp": {
    from: {
      bottom: "-100%",
    },
    to: {
      bottom: 0,
    },
  },
  "@keyframes changeBackgroundOpacity": {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
  mobileNavigationContainer: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    boxShadow: "inset 0px 2px 0px #e4e9eb",
    display: "flex",
    justifyContent: "space-around",
    fontSize: 10,
  },
  mobileMenuExtras: {
    position: "fixed",
    bottom: 60,
    height: "100vh",
    display: "none",
    width: "100%",
    zIndex: 1001,
    background: Color.rgb(0, 0, 0).alpha(0.3).toString(),
  },
  mobileMenuExtras__header: {
    display: "flex",
    justifyContent: "flex-end",
  },
  mobileMenuExtras__open: {
    display: "block",
    animation: "$changeBackgroundOpacity 0.5s ease-in",
  },
  mobileMenuExtras__items: {
    padding: 20,
    position: "absolute",
    bottom: "-100%",
    background: "white",
    width: "100%",
    left: 0,
    animation: "$moveMenuUp 0.5s ease-in-out forwards",
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
}));

export { useStyles };
