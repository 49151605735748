import { responsiveStyles } from "@kolibrisoftware/helpers-util";
import Color from "color";
import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  primaryNavigationWrapper: responsiveStyles({
    display: "grid",
    gridTemplateRows: "auto 1fr",
    width: "100vw",
    height: "100vh",
    overflow: "hidden",
  })
    .m({ height: "calc(100vh - 60px)" }, { propagate: true })
    .getStyle(),
  primaryNavigationContainer: responsiveStyles({
    position: "relative",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 36px",
    borderBottom: "1px solid #e7e7ed",
    zIndex: 100,
    maxWidth: "100vw",
  })
    .s(
      {
        paddingLeft: 24,
        paddingRight: 24,
        justifyContent: "center",
      },
      { propagate: true }
    )
    .getStyle(),

  propertyDisplayName: {
    fontSize: 16,
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  logoWrapper: responsiveStyles({
    height: 40,
    flex: 1,
    minWidth: 250,
  })
    .m({ minWidth: "unset" }, { propagate: true })
    .getStyle(),
  logo: responsiveStyles({
    height: "100%",
    cursor: "pointer",
    maxWidth: "100%",
    objectFit: "contain",
  })
    .m({ maxWidth: "100%" }, { propagate: true })
    .getStyle(),

  body: {
    display: "grid",
    gridAutoRows: "auto",
    backgroundColor: Color.rgb(245, 245, 245).toString(),
    overflow: "hidden",
  },
  logoWrapperRealtorName: responsiveStyles({
    height: 40,
    display: "flex",
    alignItems: "center",
    flex: 1,
    cursor: "pointer",
    fontWeight: 600,
  })
    .s({ justifyContent: "center" }, { propagate: true })
    .getStyle(),
}));

export default useStyles;
