import { EntityType } from "@kolibrisoftware/customerportal-api";
import * as SignalR from "@microsoft/signalr";
import { HttpTransportType } from "@microsoft/signalr";
import React, { FC, useCallback, useEffect, useRef } from "react";
import {
  actions as dossierActions,
  thunks as dossierThunks,
} from "store/dossier";
import { httpWebUtil, useDispatch, useSelector } from "store/helpers";
import { thunks as listOfItemsThunks } from "store/list-of-items";
import { thunks as questionnaireThunks } from "store/questionnaire";

type Props = { children: React.ReactNode };

enum EntityActionType {
  Add = "Add",
  Update = "Update",
  Delete = "Delete",
}

type SocketResponse = {
  EntityKey: string;
  ActionType: EntityActionType;
  EntityType: EntityType;
  TimeStamp: Date;
  ParentEntityId?: string;
};

const SocketInjector: FC<Props> = ({ children }) => {
  const connectionRef = useRef<SignalR.HubConnection | null>(null);
  const dispatch = useDispatch();
  const questionnaireForm = useSelector(
    state => state.questionnaire.formStructure
  );
  const listOfItemsForm = useSelector(state => state.listOfItems.formStructure);
  const realEstateAgencyId = useSelector(
    state => state.main.companyData?.realEstateAgencyId
  );

  const documentItemUpdate = useCallback(
    async (response: SocketResponse) => {
      try {
        switch (response.ActionType) {
          case EntityActionType.Update:
          case EntityActionType.Add:
            dispatch(
              dossierThunks.updateDocument({ documentId: response.EntityKey })
            );
            break;
          case EntityActionType.Delete:
            dispatch(dossierActions.removeDocument(response.EntityKey));
            break;
        }
      } catch (error) {
        throw error;
      }
    },
    [dispatch]
  );

  const checklistItemsUpdate = useCallback(
    async (response: SocketResponse) => {
      try {
        switch (response.ActionType) {
          case EntityActionType.Update:
            dispatch(dossierThunks.updateChecklistItem(response.EntityKey));
            break;
          case EntityActionType.Add:
            break;
          case EntityActionType.Delete:
            dispatch(dossierActions.removeChecklistItem(response.EntityKey));
            break;
        }
      } catch (error) {
        throw error;
      }
    },
    [dispatch]
  );

  const formAnswerUpdate = useCallback(
    async (response: SocketResponse) => {
      switch (response.ActionType) {
        case EntityActionType.Update:
          {
            const isQuestionnaireAnswer =
              questionnaireForm.id === response.ParentEntityId;
            const isListOfItemsAnswer =
              listOfItemsForm.id === response.ParentEntityId;

            if (!isQuestionnaireAnswer && !isListOfItemsAnswer) {
              return;
            }

            const documentThunks = isListOfItemsAnswer
              ? listOfItemsThunks
              : questionnaireThunks;

            dispatch(
              documentThunks.refetchAnswer({
                formId: response.ParentEntityId || "",
                answerId: response.EntityKey,
              })
            );
          }
          break;
        case EntityActionType.Add:
          break;
        case EntityActionType.Delete:
          break;
      }
    },
    [questionnaireForm.id, listOfItemsForm.id, dispatch]
  );

  const handleAssignmentRelationUpdate = useCallback(
    async (response: SocketResponse) => {
      try {
        await dispatch(dossierThunks.updateAssignmentRelations());
      } catch (error) {
        throw error;
      }
    },
    [dispatch]
  );

  const handleCustomQuestionUpdate = useCallback(
    async (response: SocketResponse) => {
      const isListOfItemsAnswer =
        listOfItemsForm.id === response.ParentEntityId;

      if (!isListOfItemsAnswer) {
        return;
      }

      try {
        dispatch(
          listOfItemsThunks.getFormData({
            formId: response.ParentEntityId || "",
            ignoreLoadingStatus: true,
          })
        );
      } catch (error) {
        throw error;
      }
    },
    [dispatch, listOfItemsForm.id]
  );

  const handleEntityNotification = useCallback(
    async (response: string) => {
      if (!response) {
        return;
      }

      try {
        const result: SocketResponse = JSON.parse(response);

        switch (result.EntityType) {
          case EntityType.AssignmentRelation: {
            handleAssignmentRelationUpdate(result);
            break;
          }
          case EntityType.Assignment: {
            // TODO - Is there a need?
            break;
          }
          case EntityType.Checklist: {
            // TODO - Is there a need?
            break;
          }
          case EntityType.ChecklistItem: {
            checklistItemsUpdate(result);
            break;
          }
          case EntityType.Document: {
            documentItemUpdate(result);
            break;
          }
          case EntityType.FormAnswer: {
            formAnswerUpdate(result);
            break;
          }
          case EntityType.FormCustomQuestion: {
            handleCustomQuestionUpdate(result);
            break;
          }
          default: {
            return;
          }
        }
      } catch (error) {
        throw error;
      }
    },
    [
      checklistItemsUpdate,
      documentItemUpdate,
      handleAssignmentRelationUpdate,
      formAnswerUpdate,
      handleCustomQuestionUpdate,
    ]
  );

  const connect = useCallback(async () => {
    if ((window as any).__CYPRESS__ || (window as any).Cypress) return;
    try {
      const socketUrl =
        httpWebUtil?.settings()?.apiUrlCustomerPortalSocket || "";
      if (!socketUrl) {
        return;
      }
      const connection = new SignalR.HubConnectionBuilder()
        .withUrl(socketUrl, {
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets,
        })
        .configureLogging(
          process.env.NODE_ENV === "development"
            ? SignalR.LogLevel.Debug
            : SignalR.LogLevel.None
        )
        .withAutomaticReconnect()
        .build();

      connection.on("SendEntityNotification", handleEntityNotification);
      connection.on("error", console.error);

      await connection.start();
      await connection.invoke("subscribe", {
        targetEntityId: realEstateAgencyId,
      });

      connectionRef.current = connection;
    } catch (error) {
      throw error;
    }
  }, [realEstateAgencyId, handleEntityNotification]);

  useEffect(() => {
    connect();

    return () => {
      if (connectionRef.current) {
        connectionRef.current.off("SendEntityNotification");
        connectionRef.current.off("error");
        connectionRef.current.stop();
        connectionRef.current = null;
      }
    };
  }, [connect]);

  return <>{children}</>;
};

export default SocketInjector;
