import { responsiveStyles } from "@kolibrisoftware/helpers-util";
import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  desktopNavItem: responsiveStyles({
    color: theme.colors.offBlack.toString(),
    padding: "8px 16px",
    borderRadius: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  })
    .m({ padding: "8px 8px 8px 16px", fontSize: 13 }, { propagate: true })
    .getStyle(),
  desktopNavItem__active: {
    background: theme.companyTheme?.mainColor?.toString() || "#012C57",
    color: theme.companyTheme?.textColor?.toString(),
    "& $notification": {
      transform: "scale(1)",
      marginLeft: -8,
    },
  },
  notificationLabel: {
    lineHeight: "16px",
    fontSize: 11,
    fontWeight: "600",
  },
  notification: {
    transform: "scale(1.2)",
    width: 16,
    height: 16,
    marginRight: 4,
    borderRadius: "50%",
    backgroundColor: theme.companyTheme?.mainColor?.toString() || "#012C57",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transition: "all 0.1s ease",
  },
  notification__active: {
    background: "#fefefe",
    "& $notificationLabel": {
      color: "black",
    },
  },
}));

export { useStyles };
