import Color from "color";
import { FC, useEffect, useState } from "react";
import { setTheme as setCompanyTheme } from "@kolibrisoftware/customerportal-ui";
import { ThemeProvider, useTheme as defaultUseTheme } from "react-jss";
import { useSelector } from "store/helpers";

type Props = {
  children: React.ReactNode;
};
export interface ThemeInterface {
  colors: {
    primary: Color;
    secondary: Color;
    success: Color;
    danger: Color;
    warning: Color;
    info: Color;
    brandColor: Color;
    offWhite: Color;
    offBlack: Color;
    lightGray: Color;
    darkGray: Color;
  };
  companyTheme?: {
    supportColor?: Color;
    mainColor?: Color;
    textColor?: Color;
  };
}

const initialTheme: ThemeInterface = {
  colors: {
    primary: Color.rgb(52, 82, 255),
    secondary: Color.rgb(142, 68, 173),
    success: Color.rgb(40, 125, 60),
    danger: Color.rgb(217, 24, 40),
    warning: Color.rgb(185, 80, 0),
    info: Color.rgb(46, 90, 172),
    brandColor: Color.rgb(52, 82, 255),
    offWhite: Color.rgb(250, 250, 250),
    offBlack: Color.rgb(22, 22, 22),
    lightGray: Color.rgb(199, 199, 204),
    darkGray: Color.rgb(99, 99, 102),
  },
};

const ThemeInjector: FC<Props> = ({ children }) => {
  const [theme, setTheme] = useState(initialTheme);
  const companyData = useSelector(state => state.main.companyData);

  useEffect(() => {
    const supportColor = companyData?.supportColor
      ? Color(companyData.supportColor)
      : Color.rgb(255, 255, 255);

    const textColor = companyData?.textColor
      ? Color(companyData.textColor)
      : Color.rgb(255, 255, 255);

    const mainColor = companyData?.mainColor
      ? Color(companyData.mainColor)
      : Color.rgb(52, 82, 255);
    setTheme(prevTheme => {
      return {
        ...prevTheme,
        companyTheme: { supportColor, mainColor, textColor },
      };
    });

    setCompanyTheme({
      mainColor: mainColor.hex(),
      supportColor: supportColor.hex(),
      textColor: textColor.hex(),
    });
  }, [companyData]);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export const useTheme = defaultUseTheme<ThemeInterface>;
export default ThemeInjector;
