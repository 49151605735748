import { Culture } from "@kolibrisoftware/customerportal-api";
import React, {
  FC,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
} from "react";
import { useParams } from "react-router-dom";
import { thunks as dossierThunks } from "store/dossier";
import { useDispatch, useSelector } from "store/helpers";
import {
  actions as questionnaireActions,
  thunks as questionnaireThunks,
} from "store/questionnaire";

type Props = { children: React.ReactNode };

const InitialDataLayer: FC<Props> = ({ children }) => {
  const dispatch = useDispatch();
  const { dossierId, formId } = useParams();
  const initialized = useRef(false);
  const checklistItems = useSelector(state => state.dossier.checklistItems);
  const currentForm = useSelector(state => state.questionnaire.formStructure);
  const culture = useSelector(state => state.main.culture);

  const getInitialFormData = useCallback(async () => {
    await dispatch(
      questionnaireThunks.getInitialFormData({
        formId: formId || "",
        culture: culture,
      })
    );
  }, [dispatch, formId, culture]);

  const initial = useCallback(async () => {
    if (!!initialized.current) return;

    if (!checklistItems || checklistItems.length === 0) {
      await dispatch(
        dossierThunks.loadAssignment({ assignmentId: dossierId || "" })
      );
    }
    if (formId !== currentForm.id) {
      dispatch(questionnaireActions.clearState());
    }
    // Extract this to separate callback

    await getInitialFormData();
  }, [
    formId,
    dispatch,
    checklistItems,
    dossierId,
    currentForm,
    getInitialFormData,
  ]);

  useLayoutEffect(() => {
    if (!initialized.current) {
      initial();
      initialized.current = true;
    }
  }, [initial]);

  // useEffect just for initial form data = with culture as dep

  useEffect(() => {
    getInitialFormData();
  }, [getInitialFormData]);

  return <>{initialized.current && children}</>;
};

export { InitialDataLayer };
