import {
  Culture,
  Client as CustomerPortalClient,
  FormAnswerEditor,
  FormAnswerUpdateRequest,
  FormElementExtended,
  SearchFormAnswerResponse,
  SearchFormElementResult,
  UserFormAnswer,
} from "@kolibrisoftware/customerportal-api";
import { AnswerSavingStatus } from "enums/answer-saving-status";
import { FormLoadingStatus } from "enums/form-loading-status";
import { createErrorToast } from "helpers/create-new-toast";
import { createGenericSearchAll } from "helpers/form-elements-search";
import { createThunk } from "store/helpers";
import { actions as toastActions } from "store/toasts";
import { actions as questionnaireActions } from "./index";

export type GetFormStructurePayload = {
  formId: string;
  culture?: Culture;
};

const getInitialFormData = createThunk(
  "questionnaire/getInitialFormData",
  async (
    { settings, dispatch, http, getState },
    args: GetFormStructurePayload
  ) => {
    const take = 100;
    const { formId, culture } = args;
    try {
      if (!settings?.apiUrlCustomerPortal) {
        throw new Error("Could not retrieve the url for customer portal api");
      }
      const state = getState();
      const realEstateAgencyId = state.main.companyData?.realEstateAgencyId;
      if (!realEstateAgencyId) {
        throw new Error("Missing Real Estate agency id");
      }
      const client = new CustomerPortalClient(
        settings?.apiUrlCustomerPortal,
        http
      );
      const genericSearchAll = createGenericSearchAll(
        realEstateAgencyId,
        formId,
        take
      );

      const checklistItem = state.dossier.checklistItems.find(
        item => item.formId === formId
      );

      if (checklistItem) {
        dispatch(
          questionnaireActions.setFormEditableStatus(checklistItem.status)
        );
      }
      const supportedCultures = await client
        .getFormSupportedCultures(realEstateAgencyId, formId)
        .then(data => data.supportedCultures);

      let formCulture = culture || state.main.culture;

      if (
        !supportedCultures?.includes(formCulture) &&
        supportedCultures?.length
      ) {
        formCulture = supportedCultures[0];
      }

      const formStructureResult = await client.getForm(
        realEstateAgencyId,
        formId,
        formCulture
      );

      const response = await client.searchFormElements(
        realEstateAgencyId,
        formId,
        {
          skip: 0,
          take,
          culture: formCulture,
        }
      );

      if (!response) {
        throw new Error("No form elements found.");
      }

      const { totalResults: totalFormElements, result: formElementsResult } =
        response;
      let formElements = formElementsResult || [];

      if (totalFormElements && totalFormElements > take) {
        const remainingElements = await genericSearchAll<
          SearchFormElementResult,
          FormElementExtended
        >(
          totalFormElements,
          client.searchFormElements.bind(client),
          formCulture
        );
        formElements = [...formElements, ...remainingElements];
      }

      let { result: answersResult, totalResults: totalAnswers } =
        await client.searchAnswers(realEstateAgencyId, formId, {
          skip: 0,
          take,
        });
      let formAnswers = answersResult || [];

      if (totalAnswers && totalAnswers > take) {
        const remainingAnswers = await genericSearchAll<
          SearchFormAnswerResponse,
          UserFormAnswer
        >(totalAnswers, client.searchAnswers.bind(client));
        formAnswers = [...formAnswers, ...remainingAnswers];
      }

      dispatch(questionnaireActions.setFormStructure(formStructureResult));
      dispatch(questionnaireActions.setAnswers(formAnswers));
      dispatch(questionnaireActions.setElements(formElements));
      dispatch(
        questionnaireActions.setFormLoadingStatus(FormLoadingStatus.Success)
      );
    } catch (error: any) {
      const errorMessage = error.message
        ? error.message
        : `There was an issue while loading the form structure with form id: ${formId}`;
      const toast = createErrorToast(errorMessage);
      dispatch(toastActions.addToast(toast));
      dispatch(
        questionnaireActions.setFormLoadingStatus(FormLoadingStatus.Failed)
      );
      throw error;
    }
  }
);

export type UpdateQuestionPayload = {
  questionId: string;
  updatedAnswerPayload: FormAnswerUpdateRequest;
};

const setQuestionAnswer = createThunk(
  "questionnaire/setQuestionAnswer",
  async (
    { settings, dispatch, getState, http },
    args: UpdateQuestionPayload
  ) => {
    const timeout = setTimeout(
      () =>
        dispatch(
          questionnaireActions.setSavingStatus(AnswerSavingStatus.InProgress)
        ),
      200
    );
    try {
      if (!settings?.apiUrlCustomerPortal) {
        throw new Error("Could not retrieve the url for customer portal api");
      }

      const state = getState();
      const realEstateAgencyId = state.main.companyData?.realEstateAgencyId;
      if (!realEstateAgencyId) {
        throw new Error("Missing real estate agency id");
      }
      const { updatedAnswerPayload, questionId } = args;

      const client = new CustomerPortalClient(
        settings?.apiUrlCustomerPortal,
        http
      );
      if (!state.questionnaire.formStructure.id || !updatedAnswerPayload) {
        throw new Error("There is no form id present");
      }
      const updatedAnswer: UserFormAnswer = {
        ...updatedAnswerPayload,
        blueprintElementId: questionId,
        formId: state.questionnaire.formStructure.id,
        id: updatedAnswerPayload.formAnswerId,
      };
      dispatch(questionnaireActions.setQuestionAnswer(updatedAnswer));

      await client.update(
        realEstateAgencyId,
        state.questionnaire.formStructure.id,
        updatedAnswerPayload
      );

      dispatch(questionnaireActions.removeModifiedAnswer(updatedAnswer.id));
      dispatch(questionnaireActions.setSavingStatus(AnswerSavingStatus.Saved));

      if (timeout) {
        clearTimeout(timeout);
      }
    } catch (error: any) {
      if (timeout) {
        clearTimeout(timeout);
      }
      dispatch(questionnaireActions.setSavingStatus(AnswerSavingStatus.Error));
      const toast = createErrorToast(error);
      dispatch(toastActions.addToast(toast));
      throw error;
    }
  }
);

const refetchAnswer = createThunk(
  "questionnaire/refetchAnswer",
  async (
    { settings, http, dispatch, getState },
    args: { formId: string; answerId: string }
  ) => {
    try {
      if (!settings?.apiUrlCustomerPortal) {
        throw new Error("Could not retrieve the url for customer portal api");
      }
      const state = getState();
      const realEstateAgencyId = state.main.companyData?.realEstateAgencyId;
      if (!realEstateAgencyId) {
        throw new Error("Missing Real Estate agency id");
      }
      const client = new CustomerPortalClient(
        settings?.apiUrlCustomerPortal,
        http
      );

      const answer = await client
        .searchAnswers(realEstateAgencyId, args.formId, {
          skip: 0,
          take: 1,
          ids: [args.answerId],
        })
        .then(data => data.result?.[0]);

      if (!answer) {
        throw new Error("No answer found");
      }

      dispatch(questionnaireActions.setQuestionAnswer(answer));

      if (answer.id && answer.editor !== FormAnswerEditor.User) {
        dispatch(questionnaireActions.pushModifiedAnswer(answer.id));
      }
    } catch (error: any) {
      const errorMessage = error.message
        ? error.message
        : `There was an issue while refetching the answer with answer id: ${args.answerId}`;
      const toast = createErrorToast(errorMessage);
      dispatch(toastActions.addToast(toast));
      throw error;
    }
  }
);

const thunks = { getInitialFormData, setQuestionAnswer, refetchAnswer };

export default thunks;
