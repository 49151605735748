import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles((theme: ThemeInterface) => ({
  reviewActionWrapper: {
    display: "flex",
    alignItems: "center",
  },
  pill: {},
  action: {
    width: "max-content",
    color: theme.companyTheme?.textColor?.toString(),
  },
}));
