import { faTrashCan } from "@fortawesome/pro-regular-svg-icons";
import {
  Button,
  FormElementChangeCallback,
  RadioGroup,
  TextInput,
} from "@kolibrisoftware/customerportal-ui";
import { useDefaultMediaQueries } from "@kolibrisoftware/hooks-util";
import {
  ChecklistItemStatusType,
  Culture,
  FormCustomQuestionExtended,
  FormElementExtended,
} from "@kolibrisoftware/customerportal-api";
import classnames from "classnames";
import { useTheme } from "injectors/theme";
import useQuestionAnswer from "modules/list-of-items/hooks/useElementAnswer";
import useQuestionContractOptions from "modules/list-of-items/hooks/useQuestionContractOptions";
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "store/helpers";
import { thunks as listOfItemsThunks } from "store/list-of-items";
import { useStyles } from "./styles";

function isFormElementExtended(
  obj: FormElementExtended | FormCustomQuestionExtended
): obj is FormElementExtended {
  return "translations" in obj;
}

type Props = {
  questionData: FormElementExtended | FormCustomQuestionExtended;
  onTitleChange?: (title: string) => void;
};

const ContractQuestion: FC<Props> = ({ questionData, onTitleChange }) => {
  const questionOptions = useQuestionContractOptions();
  const theme = useTheme();
  const styles = useStyles({ theme });
  const { isScreenXS, isScreenS } = useDefaultMediaQueries();
  const { formEditableStatus, isDeletingQuestion } = useSelector(
    state => state.listOfItems
  );

  const inputRef = useRef<HTMLInputElement | null>(null);
  const questionAnswer = useQuestionAnswer(questionData.id);
  const dispatch = useDispatch();

  const translation = isFormElementExtended(questionData)
    ? questionData.translations?.find(
        translation => translation.culture === Culture.Nl
      ) || questionData?.translations?.[0]
    : { title: questionData.text };

  const [questionTranslation, setQuestionTranslation] = useState<string>(
    translation?.title || ""
  );

  const handleAnswerChange: FormElementChangeCallback = useCallback(
    event => {
      const value = event.currentTarget.value;

      if (!questionAnswer?.id) {
        return;
      }
      dispatch(
        listOfItemsThunks.setQuestionAnswer({
          answerId: questionAnswer?.id || "",
          answerValue: value,
          useCase: "contract",
        })
      );
    },
    [dispatch, questionAnswer?.id]
  );

  const handleTitleSubmit = useCallback(() => {
    if (!inputRef || !inputRef.current) {
      return;
    }
    const title = inputRef.current.value;
    onTitleChange?.(title);
  }, [onTitleChange]);

  const handleOnBlur = useCallback(() => {
    if (inputRef && inputRef.current) {
      handleTitleSubmit();
    }
  }, [handleTitleSubmit]);

  const handleInputKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        handleTitleSubmit();
      }
    },
    [handleTitleSubmit]
  );

  const extraStyles = useMemo(() => {
    const result: React.CSSProperties = {};
    result.backgroundColor = "#fdfdfd";
    result.color = theme.colors.offBlack.toString();
    return result;
  }, [theme]);

  const textInputDisabled = useMemo(() => {
    switch (true) {
      case formEditableStatus !== ChecklistItemStatusType.Open:
        return true;
      case !!onTitleChange:
        return false;
      default:
        return true;
    }
  }, [formEditableStatus, onTitleChange]);

  const handleQuestionDelete = useCallback(() => {
    onTitleChange?.("");
  }, [onTitleChange]);

  const currentAnswerLabel = useMemo(
    () =>
      questionOptions.find(
        item => item.value === questionAnswer?.contractListOfGoodOption
      )?.label,
    [questionOptions, questionAnswer]
  );

  const textInputCySelector = textInputDisabled
    ? "ListOfItemsQuestionTextInputDisabled"
    : "ListOfItemsQuestionTextInput";

  useEffect(() => {
    setQuestionTranslation(translation?.title || "");
  }, [translation?.title]);

  return (
    <div className={styles.questionContainer}>
      <div className={styles.textInputContainer}>
        <TextInput
          data-cy={textInputCySelector}
          ref={inputRef}
          name={questionData?.id || "new-question-label"}
          value={questionTranslation}
          onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
            setQuestionTranslation(event.target.value)
          }
          onBlur={handleOnBlur}
          onKeyDown={handleInputKeyDown}
          disabled={textInputDisabled}
          extraStyles={extraStyles}
        />

        {!textInputDisabled && (
          <div
            className={classnames(
              styles.textInputCustomQuestionAction,
              styles.textInputDeleteAction
            )}
          >
            <Button
              data-cy="ListOfItemsQuestionDeleteButton"
              icon={faTrashCan}
              onClick={handleQuestionDelete}
              style={{ padding: 0 }}
              disabled={isDeletingQuestion}
              isQuestionAction={true}
            />
          </div>
        )}
      </div>

      <div
        className={classnames(
          styles.radioInputContainer,
          styles.listOfItemsRadioInput
        )}
      >
        {(isScreenXS || isScreenS) && (
          <div className={styles.radioAnswerContainer}>
            <p>{currentAnswerLabel}</p>
          </div>
        )}
        <div className={styles.radioGroupWrapper}>
          <RadioGroup
            className={styles.radioGroup}
            name={questionData.id || "contract-question"}
            value={questionAnswer?.contractListOfGoodOption}
            values={questionOptions}
            onChange={handleAnswerChange}
            disabled={formEditableStatus !== ChecklistItemStatusType.Open}
          />
        </div>
      </div>
    </div>
  );
};

export { ContractQuestion };
