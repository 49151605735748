import {
  EntityPresence,
  EntityPresenceAction,
  EntityType,
  FormType,
  PresenceMember,
  RelationType,
} from "@kolibrisoftware/customerportal-api";
import * as SignalR from "@microsoft/signalr";
import uniqBy from "lodash-es/uniqBy";
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { selectAssignmentRelations } from "store/dossier/selectors";
import { httpWebUtil, useSelector } from "store/helpers";
import { selectCurrentUserId } from "store/main/selectors";
import { Member } from ".";
import useMockPresence from "./hooks/useMockPresence";

type Props = {
  children: React.ReactNode;
  formType: FormType;
};

export const PresenceContext = createContext<{
  currentMembers: Member[];
  leavePresence: () => void;
} | null>(null);

export const PresenceProvider = ({ children, formType }: Props) => {
  const sliceToSelectFrom =
    formType === FormType.Questionnaire ? "questionnaire" : "listOfItems";
  const isInitialized = useRef(false);
  const hubConnectionRef = useRef<SignalR.HubConnection | null>(null);
  const formId =
    useSelector(state => state[sliceToSelectFrom]?.formStructure?.id) || "";
  const [currentMembers, setCurrentMembers] = useState<Member[]>([]);
  const userId = useSelector(selectCurrentUserId);
  const dossierRelations = useSelector(selectAssignmentRelations);
  const employees = useSelector(state => state.dossier.employees);

  const mockHubConnection = useMockPresence();

  const relationId = useMemo(() => {
    return (
      dossierRelations.find(dossierRelation => dossierRelation.subId === userId)
        ?.relationId || ""
    );
  }, [dossierRelations, userId]);

  const subscribeBody = useMemo(
    () => ({
      linkedRelationId: relationId ?? "",
      linkedRelationType: RelationType.Contact,
      targetEntityId: formId ?? "",
      targetEntityType: EntityType.Form,
    }),
    [formId, relationId]
  );

  const updateCurrentMembers = useCallback(
    (currentMembers: PresenceMember[]) => {
      // Filter out current logged in relation
      currentMembers = currentMembers.filter(
        currentMember => currentMember.RelationId !== relationId
      );

      // Filter out possible duplicates
      currentMembers = uniqBy(
        currentMembers,
        (currentMember: PresenceMember) => currentMember.RelationId
      );

      const members = currentMembers.reduce((state, currentMember) => {
        const member = dossierRelations.find(
          dossierRelation =>
            dossierRelation.relationId === currentMember.RelationId
        );

        if (member) {
          state.push({
            displayName: member.displayName || "",
            type: currentMember.Type || RelationType.Contact,
          });
        }

        if (!member && currentMember.Type === RelationType.Employee) {
          const employee = employees.find(
            employee => employee.id === currentMember.RelationId
          );

          state.push({
            displayName: employee?.displayName || "",
            type: currentMember.Type,
          });
        }

        return state;
      }, [] as Member[]);

      setCurrentMembers(members);
    },
    [dossierRelations, relationId, employees]
  );

  const handlePresenceNotification = useCallback(
    (response: string) => {
      const result = JSON.parse(response) as EntityPresence;
      updateCurrentMembers(result.CurrentMembers || []);
    },
    [updateCurrentMembers]
  );

  const initializeHubConnection = useCallback(async () => {
    const socketUrl =
      httpWebUtil.settings()?.apiUrlCustomerPortalPresenceSocket;

    if (!socketUrl) {
      return;
    }

    if (
      !mockHubConnection &&
      (isInitialized.current || !formId || !relationId)
    ) {
      return;
    }

    isInitialized.current = true;

    const hubConnection =
      mockHubConnection ??
      new SignalR.HubConnectionBuilder()
        .withUrl(socketUrl, {
          skipNegotiation: true,
          transport: SignalR.HttpTransportType.WebSockets,
        })
        .configureLogging(
          process.env.NODE_ENV === "development"
            ? SignalR.LogLevel.Debug
            : SignalR.LogLevel.None
        )
        .withAutomaticReconnect()
        .withHubProtocol(new SignalR.JsonHubProtocol())
        .build();

    hubConnection.on("SendPresenceUpdate", handlePresenceNotification);
    hubConnection.onreconnected(async () => {
      await hubConnection.invoke("NotifyEntityPresence", {
        ...subscribeBody,
        action: EntityPresenceAction.Join,
      });
    });

    await hubConnection.start();
    await hubConnection.invoke("NotifyEntityPresence", {
      ...subscribeBody,
      action: EntityPresenceAction.Join,
    });

    hubConnectionRef.current = hubConnection;
  }, [
    subscribeBody,
    formId,
    relationId,
    mockHubConnection,
    handlePresenceNotification,
  ]);

  const leavePresence = useCallback(async () => {
    if (
      hubConnectionRef.current?.state === SignalR.HubConnectionState.Connected
    ) {
      await hubConnectionRef.current?.invoke("NotifyEntityPresence", {
        ...subscribeBody,
        action: EntityPresenceAction.Leave,
      });

      hubConnectionRef.current?.off("SendPresenceUpdate");
      hubConnectionRef.current?.stop();
      hubConnectionRef.current = null;
    }
  }, [subscribeBody]);

  useEffect(() => {
    initializeHubConnection();
  }, [initializeHubConnection]);

  useEffect(() => {
    return () => {
      leavePresence();
    };
  }, [leavePresence]);

  return (
    <PresenceContext.Provider value={{ currentMembers, leavePresence }}>
      {children}
    </PresenceContext.Provider>
  );
};
