import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMedia, useTranslation } from "@kolibrisoftware/hooks-util";
import { useStyles } from "./styles";
import { faWarning } from "@fortawesome/pro-regular-svg-icons";
import { useTheme } from "injectors/theme";
import classNames from "classnames";

export const ModifiedLabel = () => {
  const t = useTranslation();
  const theme = useTheme();
  const styles = useStyles({ theme });

  const isScreenUnder1350 = useMedia("(max-width:1350px)");

  return (
    <span
      data-cy="ModifiedAnswerLabel"
      className={classNames(styles.postIt, {
        [styles.mobile]: isScreenUnder1350,
      })}
    >
      <FontAwesomeIcon icon={faWarning} />
      {t("common.modified")}
    </span>
  );
};
